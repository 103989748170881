import { NavigationItem } from "./production-views";
import { EPSGraphView, VerticalGraph } from "./vertical-views";

export interface Graph {
  data: any;
  isMainGraph?: boolean;
  colData:any;
}

export interface GraphContainer {
  title: String;
  type: GraphType | null;
  navigation: Navigation;
  subMenu?: NavigationItem<any>[];
}

export interface GraphMenu extends Array<GraphMenuItem> {};
export interface GraphViews extends Array<GraphView> {};


export interface GraphNavigation {
  month?: GraphViews;
  qtd?: GraphViews;
  ytd?: GraphViews;
}

export interface Navigation {
  type: NavigationType | null;
  nav: GraphNavigation | GraphViews | GraphMenu | null;
}

export interface GraphMenuItem {
  title: String;
  data: GraphNavigation | GraphViews;
  isPercentage?: boolean; // used for commodities graph
}

export interface GraphView {
  title: String;
  graph: Graph | EPSGraphView;
}



/**
 * Contains types of all graphs by name
 */
export enum GraphType {
  Sales = 'Sales',
  OI = 'OI',
  EBITDA = 'EBITDA',
  EPS = 'EPS',
  NI = 'NI',
  SBU = 'SBU',
  TOP_PRODUCTS = 'TOP_PRODUCTS',
  Commodities = 'Commodities',
  TERRITORY = 'TERRITORY',
  FCF= 'FCF',
  ICL_FCF='ICL_FCF',
  POTASH_PRICES = 'POTASH_PRICES',
  TOP_CUSTOMERS = 'TOP_CUSTOMERS',
  KPI = "KPI",
  POTASH_AVG = 'POTASH_AVG',
  PRODUCTION_KTM= 'PRODUCTION_KTM',
  RT_PRODUCTION = 'RT_PRODUCTION',
  PRODUCTION_DIVISION = 'PRODUCTION_DIVISION',
  CAPEX = 'CAPEX',
  GROSS = 'GROSS',
  SALES_PERCENT = 'SALES_PERCENT',
  RFC = 'RFC',
  STOCKS = 'STOCKS'
}

export const chartTypeMap = new Map<GraphType, string>([
  [GraphType.STOCKS, 'stocks'],
  [GraphType.Sales, 'bar chart'],
  [GraphType.OI, 'bar chart'],
  [GraphType.EBITDA, 'bar chart'],
  [GraphType.EPS, 'bar chart'],
  [GraphType.NI, 'bar chart'],
  [GraphType.SBU, 'battery'],
  [GraphType.TOP_PRODUCTS, 'column char'],
  [GraphType.Commodities, 'bar chart'],
  [GraphType.TERRITORY, 'map'],
  [GraphType.FCF, 'bar chart'],
  [GraphType.ICL_FCF, 'column chart'],
  [GraphType.POTASH_PRICES, 'table'],
  [GraphType.TOP_CUSTOMERS, 'column chart'],
  [GraphType.KPI, 'gauge'],
  [GraphType.POTASH_AVG, 'bar chart'],
  [GraphType.PRODUCTION_KTM, 'table'],
  [GraphType.RT_PRODUCTION, 'line chart'],
  [GraphType.PRODUCTION_DIVISION, 'bar chart'],
  [GraphType.CAPEX, 'gauge chart'],
  [GraphType.GROSS, 'bar chart'],
  [GraphType.SALES_PERCENT, 'area chart'],
  [GraphType.RFC, 'bar chart'],
]);

export enum NavigationType {
  GraphNavigation = 'GraphNavigation',
  GraphViews = 'GraphViews',
  GraphMenu = 'GraphMenu'
}

export enum GraphViewType {
  'vertical',
}

export interface GraphColumn {
  colName: String;
  colGroupName: String;
}

export enum TimePeriods {
  month = 'M',
  qtd = 'QTD',
  ytd ='YTD',
}

export enum Divisions {
  ICL = 'ICL',
  IP = 'IP',
  POTASH = 'POTASH',
  PS = 'PS',
  GS = 'GS'
}
