import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Animations } from 'src/app/animations';
import { HorizontalBarGraphUnit, HorizontalItemRow } from 'src/app/model/horizontal-graph';
import { VerticalGraphItem } from 'src/app/model/vertical-views';
import { ISelectedItem } from '../horizontal-bar-graph/horizontal-bar-graph.component';
import { Subject, takeUntil } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { chartTypeMap, GraphType} from 'src/app/model/generic-graph';

@Component({
  selector: 'app-horizontal-bar',
  templateUrl: './horizontal-bar.component.html',
  styleUrls: ['./horizontal-bar.component.scss'],
  animations: [
    Animations.openClose
  ]
})
export class HorizontalBarComponent {
  @Input() maxValue: number = 1;
  @Input() selected: boolean = false;
  @Input() withGestures: boolean = true;
  @Input() item: HorizontalItemRow = {} as HorizontalItemRow;
  @Input() selectedItem?: ISelectedItem | null;
  @Output() onSelectTitle: EventEmitter<String> = new EventEmitter<String>()
  @Output() onUpdateItem: EventEmitter<ISelectedItem> = new EventEmitter<ISelectedItem>()
  @Input() isLoading: boolean = true;
  @Input() navState: any = null;
  @Input() sectionTitle: any = null;
  private _destroyed$ = new Subject();
  public currentPath: any = {};
  
  public width: number = 1;

  constructor(private dataService: DataService) {}


  ngOnChanges(): void {
    setTimeout(() => {
      this.width = (this.item.value.value / this.maxValue) * 100;
    }, 0);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.width = (this.item.value.value / this.maxValue) * 100;
    }, 0);

    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    })

  }

  sendEventToAnalytics(title:any){
    const currentSlideMap = ['last year','no comparasion','budget']
    const timeRangeMap = ['M','QTD','YTD']
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'fireEvent',
      'event_name': 'element_click',
      'division': this?.currentPath?.div || 'ICL',
      'chart_name': this?.sectionTitle|| 'Sales',
      'type': 'engagement',
      'section': 'chart',
      'chart_type': chartTypeMap.get(GraphType.TOP_CUSTOMERS),
      'chart_state': undefined,
      'time_range':timeRangeMap[this?.navState?.nav || 0], 
      'comparison_scope': currentSlideMap[(this?.navState?.slide !== undefined && this.withGestures) ? this?.navState?.slide : 1], 
      'sub_business_unit':undefined,
      'purpose':'displaying more data',
      'description': title,
    });
  }

  selectTitle(title: String): void {
    this.sendEventToAnalytics(title)
    this.onSelectTitle.emit(title);
  }

  openMoreInfo(isMoreInfo: boolean): void {
    this.updateCurrentItem({ isMoreInfo });
  }
  
  selectUnit(unit: HorizontalBarGraphUnit): void {
    this.updateCurrentItem({ unit });
  }
  
  selectColumn(column: VerticalGraphItem): void {
    this.updateCurrentItem({ column });
  }

  updateCurrentItem(update: Partial<ISelectedItem>): void {
    this.onUpdateItem.emit({...this.selectedItem, ...update})
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
  
  }
}
