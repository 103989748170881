<nav (mousedown)="onNavClick()" (touchstart)="onNavClick()">
    <ul>
        <li *ngFor="let graph of graphs">
            <button class="graph-anchor"
            data-gtm-event_name="element_click"
            data-gtm-type="navigation" 
            data-gtm-section="sub menu" 
            data-gtm-purpose="navigating screens" 
            [attr.data-gtm-division]="currentPath?.div || 'ICL'" 
            [attr.data-gtm-chart_name]="currentPath?.graph || 'StocksPanel'" 
            [attr.data-gtm-level_1]="currentPath?.div || 'ICL'" 
            [attr.data-gtm-level_2]="graph" 
            (click)="scroll(graph)">
                {{graph}}
            </button>
        </li>
    </ul>
</nav>
