import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationOptions } from 'src/app/model/queryParams';
import { SummaryBattery, SummaryBatteryItem } from 'src/app/model/vertical-views';
import { DataService } from 'src/app/services/data.service';
import { chartTypeMap, GraphType } from 'src/app/model/generic-graph';
import { Subject, takeUntil } from 'rxjs'
@Component({
  selector: 'app-battery-graph',
  templateUrl: './battery-graph.component.html',
  styleUrls: ['./battery-graph.component.scss']
})
export class BatteryGraphComponent{

  public isTwoColumns: boolean = true;
  
  /**
   * @property {NavigationOptions} navState contains the navigtaion info for the currently displyed graph (time tab, slide, dropdown and graph name)
   */
  @Input() columnIndex!: number;
  @Input() isMainGraph!: boolean;
  @Input() navState: NavigationOptions = {};
  @Input() isLoading!: boolean;
  @Input() currentSbuTitle!: any;
  @Input() type!: any;
  private _destroyed$ = new Subject();
  public currentPath: any = {};

  private _batteries!: SummaryBattery;
  @Input()
  set batteries(data: SummaryBattery) {
    if(data) {
      this._batteries = {
        ...data,
        columns: data.columns.map(item => {
          return {
            ...item,
            negative: item.value < 0,
            size: Math.abs(item.value) < 100 ? Math.abs(item.value) : 100,
          };
        })
      }
    }
    this.isTwoColumns = this._batteries?.colNames.length === 2;
  }
  get batteries(): SummaryBattery {
    return this._batteries;
  }

  constructor(private router: Router,private dataService: DataService, private ngZone: NgZone){}

  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
  trackByFn(index: number, item: any) {
    return index;
  }

  public onNavigateToGraph(item: SummaryBatteryItem): void { 
    if(!this.isMainGraph) {
      return;
    }
    const timeRangeMap = ['M','QTD','YTD']
    const currentSlideMap = ['last year','no comparasion','budget']
    window.dataLayer.push({
      'event': 'fireEvent',
      'event_name': 'element_click',
      'division':this?.currentPath?.div || 'ICL',
      'chart_name':this?.navState?.graph,
      'type': 'navigation',
      'section': 'chart',
      'chart_type': this.type,
      'chart_state':this?.navState?.dropdown || undefined,
      'time_range':timeRangeMap[this?.navState?.nav || 0], 
      'comparison_scope': currentSlideMap[this?.navState?.slide !== undefined ? this?.navState?.slide : 1], 
      'sub_business_unit':this.currentSbuTitle,
      'purpose':'switching chart',
      'description':'battery',
      'target_chart_name': `${item.title}`
    });

    this.ngZone.run(() =>
      this.router.navigate([], {
        queryParams: {
          nav: this.navState.nav,
          div: item.title,
          slide: this.navState.slide,
          graph: this.navState.graph,
          column: this.columnIndex
        }
      })
    );
  }

}
