import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NavigationItem, RTSparkChart, SubNavigationItem } from 'src/app/model/production-views';
import { VerticalGraph } from 'src/app/model/vertical-views';
import { chartTypeMap, GraphType} from 'src/app/model/generic-graph';

@Component({
  selector: 'app-business-unit-navigation',
  templateUrl: './business-unit-navigation.component.html',
  styleUrls: ['./business-unit-navigation.component.scss']
})
export class BusinessUnitNavigationComponent implements AfterViewInit {
  @Input() businessUnits: NavigationItem<RTSparkChart>[] = [];
  @Input() subBusinessUnits: SubNavigationItem<RTSparkChart>[] = [];
  @Input() currentUnitIndex: number = 0;
  @Input() currentSubUnitIndex: number = 0;
  @Input() timeTabs: any;
  @Input() sectionTitle: any;
  @Input() currentTimeTab: any;
  @Input() dropdownOption: any;
  @Input() currentDropdownIndex: any;
  @Input() currentSlide: any;
  @Input() currentPath: any;
  @Input() type: any; 
  @Output() currentUnitIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() currentSubUnitIndexChange: EventEmitter<number> = new EventEmitter<number>();
  private debounceTimer: any;

  @ViewChild('businessUnitsDiv') businessUnitsDiv!: ElementRef;

  constructor() { }
  ngAfterViewInit(): void {
    if(this.currentUnitIndex !== 0) {
      const element = this.businessUnitsDiv.nativeElement;
      if(element.children[this.currentUnitIndex]){
        const childElement = element.children[this.currentUnitIndex];
        element.scrollLeft = childElement.offsetLeft;
      }
    }
  }

  public changeUnit(unitIndex: number): void {
    const currentSlideMap = ['last year','no comparasion','budget']
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'fireEvent',
      'event_name': 'element_click',
      'division': this?.currentPath?.div || 'ICL',
      'chart_name': this?.sectionTitle || 'Sales',
      'type': 'engagement',
      'section': 'chart',
      'chart_type': chartTypeMap.get(this?.type ?? GraphType.Sales),
      'chart_state':this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
      'time_range':this?.timeTabs?.[this?.currentTimeTab || 0]?.name || undefined,
      'comparison_scope':currentSlideMap?.[this?.currentSlide] ||  undefined,
      'sub_business_unit':this.businessUnits?.[this.currentUnitIndex]?.title || undefined,
      'purpose':'clicking on a sub business units tab',
      'description': this.businessUnits?.[unitIndex]?.title || undefined,
    });

    this.currentUnitIndex = unitIndex;
    this.currentUnitIndexChange.emit(this.currentUnitIndex);
    if(this.subBusinessUnits.length > 0){
      this.changeSubUnit(0);
    }
  }
  public changeSubUnit(subUnitIndex: number): void {
    this.currentSubUnitIndex = subUnitIndex;
    this.currentSubUnitIndexChange.emit(this.currentSubUnitIndex);
  }

  public onScroll($event: any): void {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      if( this?.currentPath?.graph !== "StocksPanel"){
        const currentSlideMap = ['last year','no comparasion','budget']
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'fireEvent',
          'event_name': 'element_slide',
          'division': this?.currentPath?.div || 'ICL',
          'chart_name': this?.sectionTitle || 'Sales',
          'type': 'engagement',
          'section': 'chart',
          'chart_type': chartTypeMap.get(this?.type ?? GraphType.Sales),
          'chart_state':this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
          'time_range':this?.timeTabs?.[this?.currentTimeTab || 0]?.name || undefined,
          'comparison_scope': currentSlideMap?.[this?.currentSlide] || undefined,
          'sub_business_unit':this.businessUnits?.[this.currentUnitIndex]?.title || undefined,
          'purpose':'sliding between sub business units'
        });
      }
    }, 200);
  }
}
