import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { RTSparkChart, RTSparkChartColumn } from 'src/app/model/production-views';
import { formatDate } from '@angular/common';
import { Subject, takeUntil} from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { chartTypeMap, GraphType } from 'src/app/model/generic-graph';
/**
 * @description
 * This component is responsible for rendering a sparkline chart using ECharts. It takes in data, processes it, and displays it in a sparkline chart format.
 */
@Component({
  selector: 'app-spark-chart',
  templateUrl: './spark-chart.component.html',
  styleUrls: ['./spark-chart.component.scss'],
})
export class SparkChartComponent implements OnInit {
  constructor(private dataService: DataService) {}

  @Input() data: RTSparkChart = {} as RTSparkChart;

  public options: EChartsOption = {};
  public mergeOptions: any = {};
  private chartInstance: any;
  private shouldHighlightDefault: boolean = true;
  private debounceTimer: any;
  private tooltipChangeSubject: Subject<any> = new Subject();
  public currentPath: any = {};
  private _destroyed$ = new Subject();

  private baseOptions: any = {
    textStyle: {
      fontSize: 10,
      color: '#768B95',
      fontFamily: 'InterRegular',
    },
    grid: {
      left: '10.6%',
      right: 50,
      top: 63,
      bottom: 25,
      containLabel: true,
    },
    graphic: [
      {
        elements: [
          {
            type: 'line',
            bottom: 40,
            zlevel: 1,
            shape: {
              x1: -10,
              y1: 0,
              x2: 10000,
              y2: 0,
            },
            style: {
              stroke: '#294E67',
            },
          },
        ],
      },
    ],
    dataset: {},
    xAxis: {
      id: 'main',
      name: '',
      type: 'category',
      nameLocation: 'center',
      nameGap: 25,
      axisLine: {
        lineStyle: {
          color: '#294E67',
        },
      },
      axisTick: {
        alignWithLabel: true,
        interval: function (index: number, value: string): boolean {
          return (index + 1) % 5 === 0 || index === 0;
        },
        length: 6,
      },
      axisLabel: {
        align: 'center',
        fontSize: 10,
        color: '#768B95',
        fontFamily: 'InterRegular',
        interval: function (index: number, value: string): boolean {
          return (index + 1) % 5 === 0 || index === 0;
        },
      },
      data: [],
    },
    yAxis: {
      type: 'value',
      position: 'right',
      splitNumber: 1,
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      max: null,
    },
    tooltip: {
      type: 'line',
      trigger: 'axis',
      backgroundColor: '#002949',
      borderColor: '#1A4F69',
      padding: 0,
      borderWidth: 1,
      textStyle: {
        fontFamily: 'DemoLight',
        fontSize: 18,
        lineHeight: 23,
        color: '#D1DCDD',
      },
      position: function (point: number[], params: any, dom: any, rect: any, size: any) {
        return [point[0] - size.contentSize[0] / 2, '5%'];
      },
      formatter: function (params: any) {
        return params[0].data.value != null
          ? `<div class="tooltip">
              <span class="value">${params[0].data.value}</span>
              <div class="separator"></div>
              <span class="date">${params[0].data.date}</span>
            </div>`
          : '';
      },
      axisPointer: {
        z: 0,
        lineStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: '#002949' },
            { offset: 0.5, color: '#005E87' },
            { offset: 1, color: '#002949' },
          ]),
          width: 1,
          type: 'solid',
        },
      },
    },
    series: [
      {
        id: 'budget',
        name: 'budget',
        type: 'line',
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 12,
        lineStyle: {
          color: '#00DEEF',
          width: 2,
        },
        emphasis: {
          itemStyle: {
            color: '#d1dcdd',
            borderWidth: 3,
            borderType: 'solid',
            borderColor: '#004866',
            shadowBlur: 4,
            shadowColor: '#001E34',
            shadowOffsetX: 0,
            shadowOffsetY: 2,
          },
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#2A4E50' },
            { offset: 1, color: '#002949' },
          ]),
        },
        markLine: {
          silent: true,
          symbol: 'none',
          label: {
            show: true,
            align: 'center',
            distance: 17,
            color: '#4E738B',
            fontSize: 11,
            lineHeight: 14,
            fontFamily: 'InterRegular',
            formatter: '{b}\n{value|{c}}',
            rich: {
              value: {
                color: '#768B95',
                fontSize: 14,
                lineHeight: 18,
                fontFamily: 'DemoLight',
              },
            },
          },
          lineStyle: {
            type: 'solid',
            color: '#006490',
          },
          data: [],
        },
        zlevel: 2,
        dimensions: ['colName', 'value'],
      },
    ],
  };

  ngOnInit(): void {
    this.initChartOptions();
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.mergeOptions = {
        xAxis: {
          id: 'main',
          name: this.data.cols[0].colGroupName,
          data: this.data.cols.map((col) => col.colName),
        },
        dataset: {
          dimensions: Object.keys(this.data.cols[0]),
          source: [...this.data.cols],
        },
        series: [
          {
            name: 'budget',
            markLine: {
              data: [{ name: this.data.yAxisTitle, yAxis: this.data.averageValue }],
            },
          },
        ],
      };

      if (this.isAverageValueBiggerThanMaxValue()) {
        this.mergeOptions.yAxis = { max: this.data.averageValue };
      } else {
        this.mergeOptions.yAxis = {
          type: 'value',
          position: 'right',
          splitNumber: 1,
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          max: null,
        };
      }

      this.shouldHighlightDefault = true;
    }
  }

  private isAverageValueBiggerThanMaxValue(): boolean {
    let avgBigger = true;
    this.data.cols.forEach((item) => {
      if (item.value && item.value > this.data.averageValue) {
        avgBigger = false;
      }
    });
    return avgBigger;
  }

  private initChartOptions(): void {
    this.baseOptions.xAxis.name = this.data.cols[0].colGroupName;
    this.baseOptions.xAxis.data = this.data.cols.map((col) => col.colName);
    this.baseOptions.dataset = {
      dimensions: Object.keys(this.data.cols[0]),
      source: [...this.data.cols],
    };
    this.baseOptions.series[0].markLine.data = [{ name: this.data.yAxisTitle, yAxis: this.data.averageValue }];
    if (this.isAverageValueBiggerThanMaxValue()) {
      this.baseOptions.yAxis.max = this.data.averageValue;
    }
    this.options = { ...this.baseOptions };
  }

  public onChartInit(ec: any): void {
    this.chartInstance = ec;
    this.chartInstance.on('showTip', (params: any) => {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer); 
      }
      this.debounceTimer = setTimeout(() => {
        this.tooltipChangeSubject.next(params); 
      }, 300); 
    });

    ec.on('finished', (params: any) => {
      if (this.shouldHighlightDefault) {
        this.highlightDefault();
        this.shouldHighlightDefault = false;
      }
    });

    this.tooltipChangeSubject.subscribe((params) => {
      this.handleTooltipChange(params);
    });
  }

  private highlightDefault(): void {
    if (this.chartInstance) {
      let lastDataIndex = this.data.cols.find((item) => item.value === null)
        ? this.data.cols.findIndex((item: RTSparkChartColumn) => item.value === null) - 1
        : this.data.cols.length - 1;
      this.chartInstance?.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: lastDataIndex });
      this.chartInstance?.dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: lastDataIndex });
    }
  }

  private handleTooltipChange(params: any): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'fireEvent',
      'event_name': 'element_click',
      'division':this?.currentPath?.div || 'ICL',
      'chart_name':'Production (KMT)',
      'type': 'engagement',
      'section': 'chart',
      'chart_type': chartTypeMap.get(GraphType.RT_PRODUCTION),
      'chart_state':undefined,
      'time_range':undefined,
      'comparison_scope':undefined,
      'sub_business_unit':undefined,
      'purpose':'displaying number',
      'description':'scorecard'
    });
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
}