import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CapexItem } from 'src/app/model/capex';
import { PieGraphItem } from 'src/app/model/commodities-graph';
import { GraphContainer, GraphViews } from 'src/app/model/generic-graph';
import { IntersectionState } from '../section-layout/section-layout.component';
import { Subject, takeUntil} from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { chartTypeMap, GraphType } from 'src/app/model/generic-graph';

@Component({
  selector: 'app-capex',
  templateUrl: './capex.component.html',
  styleUrls: ['./capex.component.scss']
})
export class CapexComponent {
  @Input() graphData: GraphContainer = {} as GraphContainer;
  @Input() chartId: string = '';
  public isLoading: boolean = true;

  public selectedItem: CapexItem = {} as CapexItem;
  public selectedIndex: number = 0;
  public options: any = {};
  public myChart: any;
  public items: CapexItem[] = []
  public currentPath: any = {};
  private _destroyed$ = new Subject();

  public onIntersectionChange(event: IntersectionState): void {
    if(event === IntersectionState.Inside) {
      this.isLoading = false;
    }
  }

  public basicOptions: any = {
    textStyle: {
      fontFamily: 'DemoLight',
    },
    series: [
      {
        name: 'Capex',
        type: 'pie',
        radius: [70, 90],
        itemStyle: {
          borderWidth: 3,
          borderColor: '#002947',
          color: '#01D8EA'
        },
        label: {
          show: false,
        },
        emphasis: {
          scale: false,
          label: {
            show: false,
          },
          itemStyle: {
            color: '#918EF4'
          }
        },
        data:[],
      },
      {
        name: 'Inactive',
        type: 'pie',
        radius: [83, 90],
        itemStyle: {
          borderColor: '#002949',
          borderWidth: 4,
          color: '#2C3E50',
        },
        label: {
          show: false,
        },
        emphasis: {
          scale: false,
          label: {
            show: false,
          },
          itemStyle: {
            color: 'gray'
          }
        },
        data: [],
      },
    ]
  };

  constructor(private cdr: ChangeDetectorRef, private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
    this.items = (this.graphData.navigation.nav as GraphViews)[0].graph.data;

    this.selectedItem = (this.graphData.navigation.nav as GraphViews)[0].graph.data[this.selectedIndex];
    const pieItems = (this.graphData.navigation.nav as GraphViews)[0].graph.data;
    this.basicOptions.series[0].data = pieItems.map((item: CapexItem) => ({
      value: item.budget,
      name: item.actual,
      item: {...item},
      itemStyle: {
        opacity: item.isActive ? 1 : 0,
      }
    }));
    this.basicOptions.series[1].data = pieItems.map((item: CapexItem) => ({
      value: item.budget,
      name: item.actual,
      item: {...item},
      itemStyle: {
        opacity: !item.isActive ? 1 : 0
      }
    }));
    this.options =  {...this.basicOptions}
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
  public onChartInit(chartInstance: any) {
    this.myChart = chartInstance;
    setTimeout(() => {
      this.eventsOnBarChart(chartInstance);
    });
    this.selectDefultItem();
  }

  public eventsOnBarChart(chartInstance: any): void {
    chartInstance.on('mouseup', 'series', (params: any) => {
      if(this.selectedIndex !== params.dataIndex) {
        this.selectItem(params.dataIndex,true);
      }
      this.cdr.detectChanges();
    });
  }

  private selectItem(index: number,isFromClick=false): void {
    if(isFromClick){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'fireEvent',
        'event_name': 'element_click',
        'division':this?.currentPath?.div || 'ICL',
        'chart_name':this?.graphData?.title || 'Sales',
        'type': 'engagement',
        'section': 'chart',
        'chart_type': chartTypeMap.get(GraphType.CAPEX),
        'chart_state':undefined,
        'time_range':undefined,
        'comparison_scope':undefined,
        'sub_business_unit':undefined,
        'purpose':'displaying number',
        'description':'pie slice'
      });
    }
    setTimeout(() => {
        this.myChart?.dispatchAction({type: 'downplay', seriesIndex: [0,1]});
        this.myChart?.dispatchAction({type: 'highlight', seriesIndex: [0,1], dataIndex: [index,index]});
        this.selectedIndex = index;
        this.selectedItem = (this.graphData.navigation.nav as GraphViews)[0].graph.data[index];
        this.cdr.detectChanges();
      });
  }

  private selectDefultItem(){
    const index = (this.graphData.navigation.nav as GraphViews)[0].graph.data.findIndex((item: CapexItem) => item.isDefault);
    if(index !== -1) {
      this.selectItem(index)
    } else {
      this.selectItem(0);
    }
  }
}
