<div class="horizontal-bar" [class.selected]="selected" [class.without-gestures]="!withGestures">
    <div (click)="selectTitle(item.title)" class="graph-line">
        <div class="bar" [ngStyle]="{'min-width.%' : width * .75}">
            <span class="title">{{item.title.toString()}}</span>
            <div class="graphic" [ngStyle]="{'width.%' : width * .75}"></div>
        </div>
        <div class="number" [ngClass]="{
            'down-gesture': item.value.positive < 0,
            'up-gesture': item.value.positive > 0
            }">
            {{item.value.value}}
        </div>
    </div>

    <div class="data-line" [@openClose]="selected ? 'open' : 'close'">
        <div class="data-cols" [@openClose]="!selectedItem?.isMoreInfo ? 'open' : 'close'">
            <div class="data-col">
                <div class="title">
                    KMT
                </div>
                <div class="data"
                    [ngClass]="{ 'down-gesture': item.kmt.positive < 0, 'up-gesture': item.kmt.positive > 0 }">
                    {{item.kmt.value}}
                </div>
            </div>
            <div class="data-col">
                <div class="title">
                    Price $/MT
                </div>
                <div class="data"
                    [ngClass]="{ 'down-gesture': item.price.positive < 0, 'up-gesture': item.price.positive > 0 }">
                    {{item.price.value}}
                </div>
            </div>
        </div>

        <ng-container *ngIf="item.data">
            <app-horizontal-more-info 
             [data]="item.data"
             [selectedItem]="selectedItem" 
             (onOpenMoreInfo)="openMoreInfo($event)"
             (onSelectUnit)="selectUnit($event)"
             (onSelectColumn)="selectColumn($event)"
             [sendEventToAnalytics]="sendEventToAnalytics"
             [isLoading]="isLoading"
             [title]="item.title"
             [currentPath]="currentPath"
             [navState]="navState"
             [withGestures]="withGestures"
             ></app-horizontal-more-info>
            </ng-container>
        </div>
</div>