import { Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-horizontal-view',
  templateUrl: './horizontal-view.component.html',
  styleUrls: ['./horizontal-view.component.scss']
})
export class HorizontalViewComponent {
  @Input() graphData!: GraphContainer;
  @Input() withGestures: boolean = true;
  public isLoading: boolean = true;
  public navState: any;

  constructor() { }

  public onIntersectionChange(event: IntersectionState): void {
    if(event === IntersectionState.Inside) {
      this.isLoading = false;
    }
  }
  public updateNavState (event:any){
    this.navState = event
  }
}
