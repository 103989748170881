import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Divisions } from 'src/app/model/generic-graph';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-divisions-header',
  templateUrl: './divisions-header.component.html',
  styleUrls: ['./divisions-header.component.scss']
})
export class DivisionsHeaderComponent {
  public divisions!: string[];
  public  currentDivision: string = Divisions.ICL;
  public currentPath: any = {};
  @ViewChildren('divisions') divisionsRef!: QueryList<ElementRef<HTMLButtonElement>>;

  public activeDivisionIndicatorStyles: { left: string, width: string } = { left: '9px', width: '58px' };
  private _destroyed$ = new Subject();

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.divisions = this.dataService.divisionData;
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
      if (params.div) {
        this.currentDivision = params.div;
        if(this.divisionsRef) {
          this.setSelectedDivisionStyle();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if(this.divisionsRef) {
      this.setSelectedDivisionStyle();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }

  public activateDivision(clickEvent: any, division: string): void {
    this.dataService.selectDivision(division);
  }

  public setSelectedDivisionStyle(): void {
    const index = this.divisions.findIndex(div => div === this.currentDivision);
    const activeDivRef = this.divisionsRef.toArray()[index];
    this.activeDivisionIndicatorStyles = {
      left: activeDivRef.nativeElement.offsetLeft + 'px',
      width: activeDivRef.nativeElement.offsetWidth + 'px'
    };
  }
}
