import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-browser';
import { Router } from "@angular/router";

/**
 * @description
 * This service controls the user authentication process, using @see AdalService.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  profile: any;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private msalService: MsalService, private router: Router) { }

  login() {
    try{
      if (this.msalGuardConfig.authRequest){
        this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }catch(err){
      this.router.navigate(['/'])
    }

  }

  logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: location.origin
    });
  }
  
  isLoggedIn() {
    return this.msalService.instance.getAllAccounts().length > 0 || localStorage.getItem('sso.idtoken')
  }
  isNative(){
    return localStorage.getItem('sso.idtoken')
  }

  public getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      // Check local storage first
      const storedToken = localStorage.getItem('sso.idtoken')
      if (storedToken) {
        resolve(storedToken);
        return;
      }

      // If token not in local storage, attempt to acquire a token silently
      const account = this.msalService.instance.getAllAccounts()[0];
      if (account) {
        const request = {
          scopes: [ "User.Read" ],
          account: account
        };

        this.msalService.acquireTokenSilent(request).subscribe({
          next: (response: AuthenticationResult) => {
            resolve(response.idToken);
          },
          error: (error) => {
            console.error('Token acquisition failed', error);
            // this.logout()
            reject('');
          }
        });
      } else {
        reject('');
      }
    });
  }
}
