import { Component, Input, Output, EventEmitter} from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { AreaGraph } from 'src/app/model/vertical-views';
import { DataService } from 'src/app/services/data.service';
import { Subject, takeUntil } from 'rxjs';
import { GraphType, chartTypeMap} from 'src/app/model/generic-graph';

@Component({
  selector: 'app-stacked-area-chart',
  templateUrl: './stacked-area-chart.component.html',
  styleUrls: ['./stacked-area-chart.component.scss'],
})
export class StackedAreaChartComponent{
  @Input() isLoading = true;
  @Input() selectedColumnIndex: number = 0;
  @Input() set graph(value: AreaGraph) {
    this._graph = value;
    this.initGraphOption();
  }
  @Input() colData: any[] = [];
  @Output() onBarSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUserBarSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() navState: any = null;
  @Input() currentSbuTitle: any = null;

  public myChart: any;
  public options: EChartsOption = {};
  public mergeOptions: EChartsOption = {};
  public currentPath: any = {};
  private _destroyed$ = new Subject();

  private _graph: AreaGraph = {} as AreaGraph;
  private baseOptions: any = {
    animation: true,
    animationDurationUpdate:500,
    textStyle: {
      color: '#768B95',
      fontSize: 10,
      fontFamily: 'InterRegular',
    },
    legend: {
      show: false, 
      data: [],
    },
    grid: {
      left: '3%',  
      right: '15%',  
      top: '3%',    
      bottom: '10%', 
      containLabel: true,
    },
    tooltip:{
      triggerOn: "none",
      show:false,
    },
    xAxis: [
      {
        offset:10,
        type: 'category',
        boundaryGap: false,
        data: [],
        axisLine: {
          lineStyle: {
            color: '#294E67',
          }
        },
        axisTick: {
          interval: 0,
          height: 6
        }, 
      },
      {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          show: false,
          onZero: false,
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          margin: 0,
          color: '#768B95',
          fontSize: 10,
          fontFamily: 'InterMedium',
          borderColor: '#1A4F69',
          align: 'center',
          verticalAlign: 'center',
          borderWidth: 1,
          borderType: 'solid',
          borderRadius: 4,
          backgroundColor: '#002949',
          padding: [1, 4, 2, 4],
          formatter: function (value: any) {
            return value && value;
          }
        },
        position: 'bottom',
        offset: 40,
        data: []
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 100, 
        splitLine: {
          show: false, 
        },
        axisTick: {
          show: false, 
        },
        axisLabel: {
          formatter: (value: number) => {
            if (value === 0 || value === 100) {
              return `${value}%`; 
            }
            return ''; 
          },
          fontSize: 10, 
        },
      },
    ],
    series: null,
  };
  
  

  get graph() {
    return this._graph;
  }
  constructor(private  dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
   }


   ngOnChanges(changes:any):void{
    if(changes['selectedColumnIndex'] ) {
      this.onSelectPointInTime(changes['selectedColumnIndex'].currentValue)
      this.moveHandleToPoint(changes['selectedColumnIndex'].currentValue)
    }
   }
  public onChartInit(ec: any): void {
    this.myChart = ec;
    this.initChartEvents(ec);
    this.initGraphOption();
  }

  private initChartEvents(chartInstance: any): void {
    let isDragging = false;
    let lastValue: number | null = null;
    const zr = chartInstance.getZr();
    zr.on('mousedown', (event: any) => {
      if (event.target && event.target.type === 'path') {
        event.event.stopPropagation(); 
        event.event.preventDefault();  

      }
    });

    chartInstance.on('updateAxisPointer', (params: any) => {
      if (params.axesInfo && params.axesInfo.length > 0) {
        isDragging = true;  
        lastValue = params.axesInfo[0].value;
      }
    });
  
    chartInstance.getZr().on('mouseup', () => {
      setTimeout(()=>{
        if (isDragging && lastValue !== null) {
            if(lastValue !== this.selectedColumnIndex){
              const timeRangeMap = ['M','QTD','YTD']
              const currentSlideMap = ['last year','no comparasion','budget']
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'fireEvent',
                'event_name': 'element_slide',
                'division': this?.currentPath?.div || 'ICL',
                'chart_name': this?.navState?.graph || 'Sales',
                'type': 'engagement',
                'section': 'chart',
                'chart_type': chartTypeMap.get(GraphType.SALES_PERCENT),
                'chart_state': this?.navState?.dropdown ?? undefined,
                'time_range':timeRangeMap[this?.navState?.nav || 0], 
                'comparison_scope': currentSlideMap[this?.navState?.slide !== undefined ? this?.navState?.slide : 1], 
                'sub_business_unit': this.currentSbuTitle,
                'purpose':'sliding between time periods',
                'description': `${lastValue + 1}`,
              });
              this.onSelectPointInTime(lastValue,true);
            }
          isDragging = false;  
          lastValue = null;
        }
      },100)
    });
  }

  private onSelectPointInTime(value: number,isUserAction:boolean = false): void {
    this.selectedColumnIndex = value;
    this.onBarSelect.emit(value);
    if(isUserAction){
      this.onUserBarSelect.emit({colNameStacked:this.colData[value].colName})
    }
    this.updateChartWithSelectedIndex();
  }
  
  private updateChartWithSelectedIndex(): void {
    if (this.myChart) {
      this.myChart.setOption({
        series: [
          ...this.mapCurrentData(this.graph.data),  // Main series
          ...this.mapCurrentDataBackground(this.graph.data),  // Background series
        ],
      });
    }
  }  


  private initGraphOption(): void {
    this.baseOptions.xAxis[0].data = this.colData.map(item => item.colName);
    this.baseOptions.xAxis[1].data = this.colData.map(item => {
      if (item.isPreliminary) {
        return { value: 'P' };
      } else {
        return { value: '-', textStyle: { opacity: 0 } };
      }
    });
    // Get the default point in time and set the axis pointer value safely
    const defaultPointIndex = this.getDefaultPointInTime(this.colData);
    this.baseOptions.xAxis[0].axisPointer = {
      animation: true,
      value: this.baseOptions.xAxis[0].data[defaultPointIndex], // Ensure that the value is within the available data range
      snap: true,
      triggerOn: 'mousemove|click',
      lineStyle: {
        opacity: 0,
      },
      label: {
        show: false,
      },
      handle: {
        show: true,
        margin: -10,
        color: '#004866',
        size: 28,
        shadowBlur: 2,
        shadowColor: '#001E34',
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        icon: 'path://M12 2C6.63 2 2 6.63 2 12s4.63 10 10 10 10-4.63 10-10S17.37 2 12 2z M12 8.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7z',
      },
    };
  
    this.baseOptions.series = [
      ...this.mapCurrentData(this.graph.data), // Main series
      ...this.mapCurrentDataBackground(this.graph.data), // Background series
    ];
    this.options = { ...this.baseOptions };
    this.findDefaultPointInTime(this.colData);
  }
  private moveHandleToPoint(index: number): void {
    const newAxisPointerValue = this.baseOptions.xAxis[0].data[index];
    if (this.myChart) {
      this.myChart.setOption({
        xAxis: [
          {
            type: 'category',
            axisPointer: {
              value: newAxisPointerValue, 
            },
          },
        ],
      });
    }
  }
  private getDefaultPointInTime(colData:any[]): number {
      const defaultPoint = colData.findIndex(item=> item.isDefault === true)
      const pointIndex = defaultPoint !== -1 ? defaultPoint : 0;
      return colData[this.selectedColumnIndex] ? this.selectedColumnIndex : pointIndex
  }

  private findDefaultPointInTime(colData:any[]): void {
    setTimeout(()=>{
      const pointIndex = this.getDefaultPointInTime(colData)
      this.onSelectPointInTime(pointIndex);
      this.moveHandleToPoint(pointIndex);
    },0)
  }

  private mapCurrentData(graphData: any[]): any[] {
    return graphData.map((item, index, array) => this.mapCurrentDataItem(item, index, array));
  }
  private mapCurrentDataBackground(graphData: any[]): any[] {
    return graphData.map((item, index) => this.mapCurrentDataItemBackground(item, index));
  }
  private arrayOfColors = [
    '#918EF4',
    '#EB88DB',
    '#E3E5BC',
    '#00BBF9',
    '#00F5D4',
  ];

private mapCurrentDataItem(item: any, index: any, array:any): any {
  const categoryName = item.colName;
  let stackPosition = 0;
  let markPointData = [];
  let lastValidIndex = -1;
  let arrayLength = array.length
  for (let i = 0; i < item.data.length; i++) {
    if (i <= (this.selectedColumnIndex || 0)) {
      lastValidIndex = i;
    } else {
      break;
    }
  }

  for (let i = 0; i < index; i++) {
    stackPosition += Math.max(this.graph.data[i].data[lastValidIndex], 5) || 0;
  }

  if (lastValidIndex !== -1) {
    const position = stackPosition + Math.max(item.data[lastValidIndex], 5);
    const distanceFromEnd = arrayLength - index - 1
    const maxValue =  100 - (distanceFromEnd * 5)
    if (item.data[lastValidIndex]) {
      markPointData.push({
        name: categoryName,
        coord: [lastValidIndex, Math.min(position,maxValue)],  
        label: {
          show: true,
          position: 'end',
          formatter: `${categoryName}: ${item.data[lastValidIndex]}%`,
          color: this.arrayOfColors[index],
          fontSize: 11,
          fontWeight: 500,
          offset: [5, 0],
        },
        symbol: 'circle',
        symbolSize: 0,
        itemStyle: {
          color: this.arrayOfColors[index],
        },
        tooltip: null,
        emphasis: {
          itemStyle: {
            color: this.arrayOfColors[index],
          },
          label: {
            color: this.arrayOfColors[index],
          },
        },
      });
    }
  }
  const modifiedData = item.data.map((value:any, idx:any) => idx > (this.selectedColumnIndex|| 0) ? null : value)
  return {
    name: `${categoryName}foreground${Math.random()}`,
    type: 'line',
    stack: 'foreground',
    xAxisIndex: 0,
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color:  this.arrayOfColors[index]
        },
        {
          offset: 1,
          color: 'transparent'
        }
      ]),
      opacity: '0.5',
    },
    lineStyle: {
      color:  this.arrayOfColors[index],
      width: 2,
    },
    symbol: 'circle',
    symbolSize: 0,
    itemStyle: {
      color:  this.arrayOfColors[index],
    },
    data: modifiedData,
    markPoint: {
      animation:true,
      data: markPointData,
    },
  };
}  
private mapCurrentDataItemBackground(item: any, index: any): any {
  const categoryName = item.colName;
  const modifiedData = item.data.map((value:any, idx:any) => idx > (this.selectedColumnIndex -1 || 0) ? value : null);
  return {
    name: `${categoryName}background${Math.random()}`,
    type: 'line',
    stack: 'background',
    xAxisIndex: 0,
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color:  this.arrayOfColors[index]
        },
        {
          offset: 1,
          color: 'transparent'
        }
      ]),
      opacity: '0.1',
    },
    lineStyle: {
      opacity:0.1,
      color:  this.arrayOfColors[index],
      width: 2,
    },
    symbol: 'circle',
    symbolSize: 0,
    itemStyle: {
      opacity:0.1,
      color:  this.arrayOfColors[index],
    },
    data: modifiedData,
  };
} 
ngOnDestroy(): void {
  this._destroyed$.next(true);
}
}
