import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { NavigationOptions } from 'src/app/model/queryParams';
import { SBUGraphView } from 'src/app/model/sbu-grpah';
import { DataService } from 'src/app/services/data.service';
import { chartTypeMap, GraphType } from 'src/app/model/generic-graph';
import { Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'app-sbu-slide',
  templateUrl: './sbu-slide.component.html',
  styleUrls: ['./sbu-slide.component.scss']
})
export class SbuSlideComponent{
  /**
   * @property {NavigationOptions} navState contains the navigtaion info for the currently displyed graph (time tab, slide, dropdown and graph name)
   */
  @Input() sbuGraph:SBUGraphView = {} as SBUGraphView;
  @Input() isMainGraph: boolean = true;
  @Input() dropdownGraphTitle: String = '';
  @Input() navState: NavigationOptions = {};
  @Input() isLoading: boolean = true;
  private _destroyed$ = new Subject();
  public currentPath: any = {};

  constructor(private router: Router,private dataService: DataService){}
  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
  public onNavigateToGraph(divTitle: String, bu: String): void {    
    if(!this.isMainGraph) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    const timeRangeMap = ['M','QTD','YTD']
    const currentSlideMap = ['last year','no comparasion','budget']
    window.dataLayer.push({
      'event': 'fireEvent',
      'event_name': 'element_click',
      'division':this?.currentPath?.div || 'ICL',
      'chart_name':this?.navState?.graph || 'Sales',
      'type': 'navigation',
      'section': 'chart',
      'chart_type': chartTypeMap.get(GraphType.SBU),
      'chart_state':this?.navState?.dropdown || 'Sales',
      'time_range':timeRangeMap[this?.navState?.nav || 0], 
      'comparison_scope': currentSlideMap[this?.navState?.slide !== undefined ? this?.navState?.slide : 1], 
      'sub_business_unit':undefined,
      'purpose':'switching chart',
      'description':'battery',
      'target_chart_name': `${divTitle} ${bu}`
    });

    this.router.navigate([], {
      queryParams: {
        div: divTitle,
        graph: this.navState.dropdown,
        nav: this.navState.nav,
        slide: this.navState.slide,
        bu: bu
      }
    });
  }
}
