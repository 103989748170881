<header>
    <nav>
        <ul>
            <li *ngFor="let division of divisions" #divisions>
                <button class="division"
                    data-gtm-event_name="element_click" 
                    data-gtm-type="navigation" 
                    data-gtm-section="main menu" 
                    data-gtm-purpose="navigating screens" 
                    [attr.data-gtm-division]="currentPath?.div || 'ICL'" 
                    [attr.data-gtm-chart_name]="currentPath?.graph || 'StocksPanel'" 
                    [attr.data-gtm-level_1]="division" 
                    [attr.data-gtm-level_2]="currentPath?.graph || 'StocksPanel'" 
                    [ngClass]="{'active': division === currentDivision}"
                    (click)="activateDivision($event, division)">
                    {{division}}
                </button>
            </li>
        </ul>
        <div class="active-division-indicator" [ngStyle]="activeDivisionIndicatorStyles"></div>
    </nav>
</header>