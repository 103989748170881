<ng-container *ngIf="businessUnits && businessUnits.length > 0">
    <div (scroll)="onScroll($event)" class="buisness-units-tabs" [ngClass]="{'sbu-over-one': subBusinessUnits.length < 2}" #businessUnitsDiv >
        <button *ngFor="let unit of businessUnits; let index = index"
        class="unit-tab" [ngClass]="{'active': index === +currentUnitIndex}"
        (click)="changeUnit(index)">
            <div class="unit-tab-titles" [ngClass]="{'sub-unit-tab-titles': unit.subtitle}">
                <span class="subtitle" *ngIf="unit.subtitle">{{unit.subtitle}}</span>
                <span class="title" [ngClass]="{'sub-buisness-unit' : unit.subtitle}">{{unit.title}}</span>
            </div>
        </button>
    </div>
    <div class="buisness-units-tabs" *ngIf="subBusinessUnits.length > 1">
        <button *ngFor="let subUnit of subBusinessUnits; let index = index"
            class="unit-tab sub-unit-tab" [ngClass]="{'active': index === +currentSubUnitIndex}"
            (click)="changeSubUnit(index)" >
            <span *ngIf="subBusinessUnits.length > 1">{{subUnit.title}}</span>
        </button>
    </div>
</ng-container>
