import { Component, HostBinding, Input } from '@angular/core';
import { fromEvent, throttleTime, map, Subject, takeUntil } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-graphs-anchors',
  templateUrl: './graphs-anchors.component.html',
  styleUrls: ['./graphs-anchors.component.scss'],
  host: {'[class.hidden]': '!isVisible'},
})
export class GraphsAnchorsComponent {
  @Input() graphs: string[] = [];

  private _destroyed$ = new Subject();

  private isVisible: boolean = true;
  private timeout: any;
  private direction: any;
  public currentPath: any = {};

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
  }

  public scroll(anchor: string): void {
    this.dataService.setGraph(anchor);
  }

  ngAfterViewInit(){
    this.handleScroll();
  }

  public onNavClick(): void {
    clearTimeout(this.timeout);
    this.isVisible = true;
    this.direction = 'none';
  }

  private handleScroll() : void {

    const scroll$ = fromEvent(window, 'scroll').pipe(throttleTime(10), map(()=> {
      return window.scrollY;
    }));

    let lastKnownScrollPosition = 0;

    scroll$.pipe(map(value => {
      let newValue = 'none';
     
      if(value < lastKnownScrollPosition){
        newValue = 'up';
      } else if(value > lastKnownScrollPosition){
        newValue = 'down';
      } else {
        newValue = 'none';
      }

      if (value === 0) {
        newValue = 'onTop';
      }

      lastKnownScrollPosition = value;
      return newValue;
    })).subscribe((dir) => {

      if (dir !== 'none' && dir !== this.direction) {
        this.direction = dir;
        switch (this.direction) {
          case 'onTop': {
            clearTimeout(this.timeout);
            setTimeout(() => {
              this.isVisible = true;
            });
            break;
          }
          case 'up': {
            clearTimeout(this.timeout);
            this.isVisible = true;
            if (window.scrollY > 0) {
              this.timeout = setTimeout(() => {
                this.isVisible = false;
                this.direction = null;
              }, 3000);
            }
            break;
          }
          case 'down': {
            this.isVisible = false;
            break;
          }
          default:
            break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
}
