import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Animations } from 'src/app/animations';
import { HorizontalBarGraphUnit, HorizontalItemRowData, SalesUnits } from 'src/app/model/horizontal-graph';
import { ISelectedItem } from '../horizontal-bar-graph/horizontal-bar-graph.component';
import { VerticalGraphItem } from 'src/app/model/vertical-views';

@Component({
  selector: 'app-horizontal-more-info',
  templateUrl: './horizontal-more-info.component.html',
  styleUrls: ['./horizontal-more-info.component.scss'],
  animations: [
    Animations.openClose
  ]
})
export class HorizontalMoreInfoComponent implements OnInit, OnChanges {
  @Input() selectedItem?: ISelectedItem | null;
  @Input() data: HorizontalItemRowData = {} as HorizontalItemRowData;
  @Input() isLoading: boolean = true;
  @Input() title: any = "";
  @Input() currentPath: any = {};
  @Input() navState: any = null;
  @Input() withGestures: boolean = false;
  @Input() sendEventToAnalytics!: (title: string) => void;
  @Output() onSelectUnit: EventEmitter<HorizontalBarGraphUnit> = new EventEmitter<HorizontalBarGraphUnit>();
  @Output() onOpenMoreInfo: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSelectColumn: EventEmitter<VerticalGraphItem> = new EventEmitter<VerticalGraphItem>();

  public currentUnit = HorizontalBarGraphUnit.MDOLLAR;
  public columnIndex!: number;
  public salesUnits: any = SalesUnits;
  
  constructor(private cdr: ChangeDetectorRef) { }

  private handleGraphChanged(): void {
    let index = this.data.graphs[this.currentUnit].data.findIndex((column) => column.isDefault); //get default
    if(this.selectedItem?.column) {
      const newIndex = this.data.graphs[this.currentUnit].data.findIndex(column => column.colName === this.selectedItem!.column!.colName);
        index = newIndex > -1 ? newIndex : index
    }
    if(index > -1) {
      this.selectGraphValue(index)
    } else {
      this.selectGraphValue(0);
    }
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: any): void {
    if(changes['selectedItem']) {
        const { unit, column } = this.selectedItem ?? {};
        this.handleUnitChange(unit)
        this.handleColumnChange(column)
    }
    if(changes['data']) {
      this.handleGraphChanged();
    }
  }

  private handleUnitChange(unit: HorizontalBarGraphUnit | undefined): void {
    if(unit != null) {
      this.currentUnit = unit;
    } else {
      this.currentUnit = HorizontalBarGraphUnit.MDOLLAR;
    }
  }

  private handleColumnChange(column: VerticalGraphItem | undefined): void {
    if (column) {
      const selectedColName = this.selectedItem?.column?.colName;
      let selectedIndex = this.data.graphs[this.currentUnit].data.findIndex(column => column.colName === selectedColName);
      if(selectedIndex > -1) {
        this.columnIndex = selectedIndex;
      } else {
        selectedIndex = this.data.graphs[this.currentUnit].data.findIndex((column) => column.isDefault);
        if (selectedIndex > -1) {
          this.columnIndex = selectedIndex;
        } else {
          this.columnIndex = 0;
        }
      }
    }
  }

  public selectGraphValue(colIndex: number) {
    this.columnIndex = colIndex;
    this.cdr.detectChanges();
  }

  public onUserBarSelect(columnData: VerticalGraphItem): void {
    if(columnData !== this.selectedItem?.column) {
      this.onSelectColumn.emit(columnData);
    }
  }

  public toggleMoreInfo(): void {
    this.sendEventToAnalytics(this.title)
    const isOpen = this.selectedItem?.isMoreInfo ? false : true;
    this.onOpenMoreInfo.emit(isOpen);
  }

  public updateUnit(unit: HorizontalBarGraphUnit): void {
    if(unit !== this.selectedItem?.unit) {
      this.onSelectUnit.emit(unit);
    }
  }

  /**
   * Preserve original property order
   * @param a
   * @param b
   * @returns
   */
  public originalOrder(a: any, b: any): number {
    const order: any = {
      'kmt' : 0,
      'dollarMT' : 1,
      'mDollar' : 2,
    }
    if(order[a.key] && order[b.key]) {
      return order[a.key] > order[b.key] ? 1 : -1;
    }
    else
      return 0;
  }
}
