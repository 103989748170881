import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { VerticalGraph, VerticalGraphItem, VerticalGraphType } from 'src/app/model/vertical-views';
import { GraphType, chartTypeMap} from 'src/app/model/generic-graph';
import { DataService } from 'src/app/services/data.service';
import { Subject, takeUntil } from 'rxjs';

const CANVAS_HEIGHT_PX = 230;
const PADDING_BOTTOM_CANVAS_PX = 25;
const PADDING_TOP_CANVAS_PX = 45;
const COMPUTED_CANVAS_HEIGHT_PX = CANVAS_HEIGHT_PX - PADDING_BOTTOM_CANVAS_PX - PADDING_TOP_CANVAS_PX;
const MINCHANGE_PX_OFFSET = 32;

function truncateDecimal(value: number): string {
  return value.toFixed(2).replace(/\.?0+$/, '')
}

@Component({
  selector: 'app-vertical-bar-graph',
  templateUrl: './vertical-bar-graph.component.html',
  styleUrls: ['./vertical-bar-graph.component.scss']
})
export class VerticalBarGraphComponent implements OnInit {

  @Input() type: VerticalGraphType = VerticalGraphType.SIMPLE_BAR;
  @Input() isSecondary: boolean = false;
  @Input() userData: any;
  @Input() isPercentage: any;
  @Output() onBarSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUserBarSelect: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectedColumnIndex?: number;

  private _graph: VerticalGraph = {} as VerticalGraph;
  @Input() isLoading = true;
  @Input() isProductionKmtGraph:any = false;
  public currentPath: any = {};
  private _destroyed$ = new Subject();
  @Input() navState: any = null;
  @Input() currentSbuTitle: any = null;

  @Input() set graph(value: VerticalGraph) {
    this._graph = value;
    this.initGraphOption();
    if (this.selectedColumnIndex !== undefined && this.selectedColumnIndex !== -1 && this.myChart) {
      /* fallback to default if the selected column is not in the data
      *  this is a specific case that can happen if selectedColumnIndex is self managed and not coming from the parent component
      *  in this case, if the index doesn't exist, we fallback to the default column.
      *  notice - parent components with multiple graphs should manage the selectedColumnIndex themselves.
      */
      if(this.userData) {
        const dataIndex = this.graph.data.findIndex(item => item.colName === this.userData.colName);
        if(dataIndex > -1) {
          this.selectedColumnIndex = dataIndex
          this.selectBar(dataIndex, false);
        } else {
          this.selectDefaultBar();
        }
      }
      else {
          const isSelectedValid = this?.graph?.data?.[this.selectedColumnIndex] 
          isSelectedValid ? this.selectBar(this.selectedColumnIndex) : this.selectDefaultBar()
      }
    } else {
      this.selectDefaultBar();
    }
  }
  get graph() {
    return this._graph;
  }

  public myChart: any;
  public options: EChartsOption = {};
  public mergeOptions: EChartsOption = {};

  private baseOptions: any = {
    textStyle: {
      color: '#768B95',
      fontSize: 10,
      fontFamily: 'InterRegular',
    },
    grid: {
      left: '10.6%',
      right: 10,
      top: 45,
      bottom: 25,
      containLabel: true,
    },
    graphic: [
      {
        elements: [
          {
            type: 'line',
            bottom: 52,
            zlevel: 1,
            shape: {
              x1: -10,
              y1: 0,
              x2: 10000,
              y2: 0
            },
            style: {
              stroke: '#294E67',
            },
          },
        ],
      },
    ],
    dataset: {},
    xAxis: [
      {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#294E67',
          }
        },
        axisTick: {
          interval: 0,
          height: 6
        },
        data: []
      },
      {
        id: 'preliminary',
        type: 'category',
        axisLine: {
          show: false,
          onZero: false,
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          margin: 0,
          color: '#768B95',
          fontSize: 10,
          fontFamily: 'InterMedium',
          borderColor: '#1A4F69',
          align: 'center',
          verticalAlign: 'center',
          borderWidth: 1,
          borderType: 'solid',
          borderRadius: 4,
          backgroundColor: '#002949',
          padding: [1, 4, 2, 4],
          formatter: function (params: any) {
            return params && params;
          }
        },
        position: 'bottom',
        offset: 30,
        data: []
      },
      {
        id: 'colGroupName',
        type: 'category',
        axisLine: {
          show: false,
          onZero: false,
        },
        axisTick: {
          show: false,
          length: 12,
          lineStyle: {
            color: '#1A4F69'
          }
        },
        axisLabel: {
          margin: 0
        },
        position: 'bottom',
        offset: 30,
      }
    ],
    yAxis: {
      type: 'value',
      position: 'right',
      splitNumber: 3,
      axisLabel: {
        margin: 25,
        color: '#4E738B',
        fontSize: '11px',
        fontFamily: 'InterRegular',
        formatter: (value: number, index: number) => this.formatYAxisLabel(value, index)
      },
      splitLine: {
        lineStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#002949' }, { offset: 0.5, color: '#004A6A' }, { offset: 1, color: '#002949 ' }]),
        },
      },
    },
  };

  private optionSimpleBar: any = {
    color: [
      {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#00567B' }, { offset: 1, color: '#004764' }]),global:true},
    ],
    series: [
      {
        universalTransition: {
          enabled: true,
          divideShape: 'clone',
        },
        id: 'current',
        name: 'current',
        type: 'bar',
        label: {
          position: 'top',
          fontSize: 16,
          fontFamily: 'DemoMedium',
          color: '#00BDDE',
          align: 'center',
          formatter:  (params: any): string => {
            let label = truncateDecimal(params.value);
            if(this.isPercentage){
              label+="%"
            }
            label += params.data["percent"] === 0 || params.data["percent"] ? `\n{percent|${params.data["percent"]}%}` : '';
            return label;
          },
          rich: {
            percent: {
              color: '#00DDED',
              fontSize: 12,
              fontFamily: 'DemoLight',
            },
          }
        },
        itemStyle: {
          shadowColor: 'rgba(0,33,59,0.6)',
          shadowBlur: 8,
        },
        selectedMode: true,
        select: {
          itemStyle: {
            borderWidth: 0,
            color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#00DEEF' }, { offset: 1, color: '#1A4F69' }])
          },
          label: {
            show: true,
          }
        },
        data: []
      },
      {
        type: 'bar',
        xAxisIndex: 2,
        dimensions: ["colGroupName"]
      },
    ]
  };

  private optionCompareBar: any = {
    color: [
      {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#006F9F' }, { offset: 1, color: '#1A4F69' },]),global:true},
      {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#00567B' }, { offset: 1, color: '#1A4F69' },]),global:true},
      {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: 'rgba(209,220,221,0.18)' }, { offset: 1, color: '#799A9D' },]),global:true},
    ],
    series: [
      {
        universalTransition: {
          enabled: true,
          divideShape: 'clone',
        },
        id: 'compare',
        type: 'bar',
        barGap: 0,
        stack: 'x',
        label: {
          formatter: (params:any) => `${params.value}${this.isPercentage? "%" : ""}`,
          position: 'top',
          fontSize: 16,
          fontFamily: 'DemoMedium',
          color: '#D1DCDD',
          align: 'right',
          rich: {
            percent: {
              fontSize: 12,
              fontFamily: 'DemoLight',
            },
            white: {
              color:'#D1DCDD',
              fontSize: 16,
              fontFamily: 'DemoMedium',
              position: 'top',
              align: 'right',
            },
            gray: {
              color:'#768B95',
              position: 'top',
              fontSize: 16,
              fontFamily: 'DemoMedium',
              align: 'right',
            },
          }
        },
        itemStyle: {
          shadowColor: 'rgba(0,33,59,0.6)',
          shadowBlur: 8,
        },
        select: {
          itemStyle: {
            borderWidth: 0,
            color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#D1DCDD' }, { offset: 1, color: '#799A9D' }])
          },
          label: {
            show: true,
            opacity: 1,
            formatter: (params:any) => `${params.value}${this.isPercentage? "%" : ""}`
          }
        },
        selectedMode: true,
        data: []
      },
      {
        universalTransition: {
          enabled: true,
          divideShape: 'clone',
        },
        id: 'current',
        name: 'current',
        type: 'bar',
        label: {
          position: 'top',
          fontSize: 16,
          fontFamily: 'DemoMedium',
          color: '#00BDDE',
          align: 'center',
          formatter: (params: any): string =>{
            let label = truncateDecimal(params.value);
            if(this.isPercentage){
              label+="%"
            }
            label += params.data["percent"] === 0 || params.data["percent"] ? `\n{percent|${params.data["percent"]}%}` : '';
            return label;
          },
          rich: {
            percent: {
              fontSize: 12,
              fontFamily: 'DemoLight',
            },
          }
        },
        itemStyle: {
          shadowColor: 'rgba(0,33,59,0.6)',
          shadowBlur: 8,
        },
        selectedMode: true,
        select: {
          itemStyle: {
            borderWidth: 0,
            color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#00DEEF' }, { offset: 1, color: '#1A4F69' }])
          },
          label: {
            show: true,

          }
        },
        data: []
      },
      {
        universalTransition: {
          enabled: true,
          divideShape: 'clone',
        },
        id: 'partial',
        type: 'bar',
        boundaryGap: true,
        stack: 'x',
        label: {
          formatter: (params:any) => `${params.value}${this.isPercentage? "%" : ""}`,
          position: 'top',
          fontSize: 16,
          fontFamily: 'DemoMedium',
          color: '#768B95',
          align: 'right',
          rich: {
            percent: {
              fontSize: 12,
              fontFamily: 'DemoLight',
            },
            white: {
              color:'#D1DCDD',
              fontSize: 16,
              fontFamily: 'DemoMedium',
              position: 'top',
              align: 'right',
            },
            gray: {
              color:'#768B95',
              position: 'top',
              fontSize: 16,
              fontFamily: 'DemoMedium',
              align: 'right',
            },
          }
        },
        itemStyle: {
          shadowColor: 'rgba(0,33,59,0.6)',
          shadowBlur: 8,
          opacity: 0.18
        },
        select: {
          itemStyle: {
            opacity: 0.4,
            borderWidth: 0,
            color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#D1DCDD' }, { offset: 1, color: '#799A9D' }])
          },
          label: {
            show: true,
            opacity: 1,
          }
        },
        selectedMode: true,
        data: []
      },
      {
        type: 'bar',
        xAxisIndex: 2,
        dimensions: ["colGroupName"]
      },
    ]
  };

  constructor(private  dataService: DataService) { }
  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
    this.checkGraphType();
    this.initGraphOption();
    
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['userData'] && this.userData) {
      const dataIndex = this.graph.data.findIndex(item => item.colName === this.userData.colName);
      if(dataIndex > -1) {
        this.selectedColumnIndex = dataIndex
        this.selectBar(dataIndex, false);
      }
    }

    if (changes['selectedColumnIndex'] && this.selectedColumnIndex !== undefined && this.selectedColumnIndex !== null && this.selectedColumnIndex > -1) {
      this.selectBar(this.selectedColumnIndex);
    }

    if (changes['isSecondary']) {
      this.mergeOptions = {
        series: [
          {
            name: 'current',
            select: {
              itemStyle: {
                color: this.isSecondary ?
                  new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#0093EF' }, { offset: 1, color: '#1E478D' }])
                  :
                  new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#00DEEF' }, { offset: 1, color: '#1A4F69' }])
              },
              label: {
                color: this.isSecondary ? '#038BE5' : '#00BDDE'
              }
            }
          }
        ]
      };
    }
  }

  private checkGraphType(): void {
    if (this.graph.data[0].compare) {
      this.type = VerticalGraphType.COMPARE_BAR;
    }
  }

  private formatYAxisLabel(value: number, index: number): string {
    return value !== 0 && index !== 0 ? `${value}${this.graph.columnType ? this.graph.columnType : ''}` : "";
  }

  private mapXAxisData(graphData: VerticalGraphItem[]): String[] {
    return graphData.map(item => item.colName);
  }

  private mapCurrentDataItem(item: any, isQuarterlyArray:boolean = false, isMonthlyArray:boolean = false): any { 
    const groupId = this.getGroupName(isQuarterlyArray,isMonthlyArray,item.colName)
    return {  
      groupId,
      value: item?.current?.value,
      percent: item?.current?.percent,
      isPreliminary: item.isPreliminary,
      label: {
        align: this.type === VerticalGraphType.SIMPLE_BAR ? 'center' : (item.compare && item.current.value < item.compare.value) || (item.partial && item.current.value < item.partial.value) ? 'left' : 'left'
      },
      select: {
        itemStyle: {
          color: item?.current?.value < 0 ? new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#DA4A4F' }, { offset: 1, color: '#FF6F74' }]) : undefined
        },
        label: {
          color: item?.current?.value < 0 ? '#FF6F74' : undefined,
          rich: {
            percent: {
              color: item?.current?.value < 0 ? '#FF6F74' : undefined,
            }
          }
        }
      }
    };
  }

  private getIsMonthlyAndQuarterly(graphData: VerticalGraphItem[]) :any{
    const quarterlyArray = ['Q1', 'Q2', 'Q3', 'Q4']
    const monthArray = [1,2,3,4,5,6,7,8,9,10,11,12]
    const monthArray2 = [3,6,9,12]
    const xAxisData = this.mapXAxisData(graphData);
    const isQuarterlyArray = JSON.stringify(xAxisData) === JSON.stringify(quarterlyArray)
    const isMonthlyArray = JSON.stringify(xAxisData.map(item=>Number(item))) === JSON.stringify(monthArray) || JSON.stringify(xAxisData) === JSON.stringify(monthArray2)
    return {isQuarterlyArray,isMonthlyArray}
  }
  private getGroupName(isQuarterlyArray:boolean, isMonthlyArray:boolean, colName:any ) :string{
    let groupId = ''
    if(isQuarterlyArray){
      groupId = colName
    }else if(isMonthlyArray){
      const numericalName = Number(colName)
      groupId = `Q${Math.floor((numericalName + 2) / 3)}`;
    }
    return groupId
  }
  private mapCurrentData(graphData: VerticalGraphItem[]): any[] {
    const {isQuarterlyArray,isMonthlyArray} = this.getIsMonthlyAndQuarterly(graphData)
    return graphData.map((item) => (this.mapCurrentDataItem(item,isQuarterlyArray,isMonthlyArray)))
  }

  private mapWhiteBar(labelValue: number): any {
    return {
      itemStyle: {
        opacity: 0.4,
        color: {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#006F9F' }, { offset: 1, color: '#1A4F69' }]),global:true}
      },
      select: {
        itemStyle: {
          opacity: 0.4,
          color: {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#D1DCDD' }, { offset: 1, color: '#799A9D' }]),global:true}
        },
      },
      label: {
        formatter: () => [`${truncateDecimal(labelValue)}${this.isPercentage ? "%" : ""}`].join('\n'),
        color: '#768B95',
      },
    }
  }

  private mapGrayBar(labelValue: number): any {
    return {
      itemStyle: {
        opacity: 1,
        color: {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#006F9F' }, { offset: 1, color: '#1A4F69' },]),global:true}
      },
      select: {
        itemStyle: {
          opacity: 1,
          color: {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#D1DCDD' }, { offset: 1, color: '#799A9D' }]),global:true}
        },
      },
      label: {
        formatter: () => [`${truncateDecimal(labelValue)}${this.isPercentage ? "%" : ""}`].join('\n'),
        color: '#D1DCDD',
      }
    }
  }

  private mapFutureBar(labelValue: number): any {
    return {
      itemStyle: {
        opacity: 0.18,
        color: {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: 'rgba(209,220,221,0.18)' }, { offset: 1, color: '#799A9D' }]),global:true}
      },
      select: {
        itemStyle: {
          opacity: 0.4,
          color: {...new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: 'rgba(209,220,221,0.18)' }, { offset: 1, color: '#799A9D' }]),global:true}
        },
      },
      label: {
        formatter: () => [`${truncateDecimal(labelValue)}${this.isPercentage ? "%" : ""}`].join('\n'),
        align: 'center',
        color: '#768B95',
      }
    }
  }


  private updateSeriesData(graphData: VerticalGraphItem[], seriesData: any) {
    const {isQuarterlyArray,isMonthlyArray} = this.getIsMonthlyAndQuarterly(graphData)
    seriesData[0].data = []
    seriesData[1].data = []
    seriesData[2].data = []
    graphData.forEach((item, index) => {
      let base = 0;
      let delta = 0;
      let labelDeltaValue = 0;
      let shouldFlip = false;
      let isStacked = false;
      if(item.partial) {
        base = item.partial.value;
        delta = item.compare!.value;
        labelDeltaValue = delta;
        if(item.partial.value <= 0 && item.compare!.value <= 0) {
          isStacked = true;
        }else if(item.partial.value * item.compare!.value > 0) {
          isStacked = true
        }
        //check if the values are stacked together
        if(isStacked) {
          shouldFlip = Math.abs(item.compare!.value) - Math.abs(item.partial.value) < 0;
          //check if we should flip the values 
          if(shouldFlip) {
            base = item.compare!.value;
            delta = item.partial.value - item.compare!.value;
          }else {
            base = item.partial.value;
            delta = item.compare!.value - item.partial.value;
          }
          labelDeltaValue = delta + base;
        }else {
          shouldFlip = item.compare!.value > item.partial.value;
        }
      }else {
          base = item.compare!.value;
      }
      
      // Offsets Section
      let requireOffset = false;
      if(item.compare && item.partial) {
        if(item.compare.value <= item.partial.value) {
          requireOffset = true;
        }else if(item.compare.value <= 0 && item.partial.value <= 0 && item.compare.value > item.partial.value) {
          requireOffset = true;
        }else if(shouldFlip) {
          requireOffset = true;
        }else {
          requireOffset = this.shouldOffset(item.compare.value, item.partial.value);
        }
      }
      let isPartialShown = false;
      if(item.compare && item.partial) {
        if((item.compare.value > item.partial.value && item.partial.value >= 0) || (shouldFlip && isStacked)) {
          isPartialShown = true;
        }
    }

    let hiddenLabel, shownLabel;
    if(requireOffset) {
      hiddenLabel = {
        label: {
          formatter: ''
          },
          select:{
            label:{
              formatter:""
            }
          }
        }
        shownLabel = {
          label: this.getOffsetLabel(base, labelDeltaValue, shouldFlip),
        }
      }
      const groupId = this.getGroupName(isQuarterlyArray,isMonthlyArray,item.colName)
      seriesData[0].data[index] = {
        value: base,
        groupId,
        ...((shouldFlip && isStacked) && this.mapWhiteBar(base)),
        ...(item.isFuture && this.mapFutureBar(base)),
        ...(requireOffset ? (isPartialShown ? hiddenLabel : shownLabel) : undefined)
      }
      seriesData[1].data[index] = this.mapCurrentDataItem(item,isQuarterlyArray,isMonthlyArray)
      
      seriesData[2].data[index] = item.partial && {
        value: delta,
        groupId,
        ...((shouldFlip && isStacked) && this.mapGrayBar(labelDeltaValue)),
        ...(requireOffset 
          ? (isPartialShown ? shownLabel : hiddenLabel) 
          : { 
        label: {
          formatter: () => `${labelDeltaValue}${this.isPercentage ? "%" : ""}`
        }}
        )
      }
    })
  }

  private initGraphOption(): void {
    let isPreliminary = false;

    this.baseOptions.dataset = {
      source: [...this.graph.data]
    };
    this.baseOptions.xAxis[0].data = this.mapXAxisData(this.graph.data);
    this.baseOptions.xAxis[1].data = this.graph.data.map(item => {
      if (item.isPreliminary) {
        isPreliminary = true;
        return { value: 'P' };
      } else {
        return { value: '-', textStyle: { opacity: 0 } };
      }
    });
    this.baseOptions.xAxis[2].axisTick.show = !this.graph.data.every(item => item.colGroupName === this.graph.data[0].colGroupName);

    if (this.graph.data[0].colGroupName) {
      if (isPreliminary) {
        this.baseOptions.xAxis[2].axisLabel.margin = 10;
        this.baseOptions.xAxis[2].axisTick.length = 25;
        this.baseOptions.grid.bottom = 3;
      }
      else {
        this.baseOptions.grid.bottom = 13;
      }
    }
    switch (this.type) {
      case VerticalGraphType.SIMPLE_BAR: {
        this.optionSimpleBar.series[0].data = this.mapCurrentData(this.graph.data);
        this.optionSimpleBar.series[0].barMaxWidth = this.graph.data.length < 12 ? 18 : 9;
        this.options = { ...this.baseOptions, ...this.optionSimpleBar };
        break;
      }
      case VerticalGraphType.COMPARE_BAR: {
        //set the init values of the partial and compare bars 
        
        this.updateSeriesData(this.graph.data, this.optionCompareBar.series);
        this.optionCompareBar.series[0].barMaxWidth = this.graph.data.length < 12 ? 18 : 7;
        this.optionCompareBar.series[1].barMaxWidth = this.graph.data.length < 12 ? 18 : 9;
        this.optionCompareBar.series[2].barMaxWidth = this.graph.data.length < 12 ? 18 : 7;
        this.options = { ...this.baseOptions, ...this.optionCompareBar };
        break;
      }
    }
  }

  public onChartInit(ec: any): void {
    this.myChart = ec;
    this.initChartEvents(ec);
  }

  private initChartEvents(chartInstance: any): void {
    if (this.selectedColumnIndex !== undefined && this.selectedColumnIndex !== -1) {
      this.selectBar(this.selectedColumnIndex);
    }
    else {
      this.selectDefaultBar();
    }

    chartInstance.getZr().on('click', (params: any) => {
      const pointInPixel = [params.offsetX, params.offsetY];
      const pointInGrid = chartInstance.convertFromPixel('grid', pointInPixel);
      if(pointInGrid[0] > -1 && pointInGrid[0] < this.graph.data.length) {
        const timeRangeMap = ['M','QTD','YTD']
        const currentSlideMap = ['last year','no comparasion','budget']
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'fireEvent',
          'event_name': 'element_click',
          'division': this?.currentPath?.div || 'ICL',
          'chart_name': this?.navState?.graph || 'Sales',
          'type': 'engagement',
          'section': 'chart',
          'chart_type': chartTypeMap.get(GraphType.Sales),
          'chart_state': this?.navState?.dropdown ?? undefined,
          'time_range':timeRangeMap[this?.navState?.nav || 0], 
          'comparison_scope': currentSlideMap[this?.navState?.slide !== undefined ? this?.navState?.slide : 1], 
          'sub_business_unit': this.currentSbuTitle,
          'purpose':'displaying number',
          'description': 'bar',
          });
          chartInstance?.dispatchAction({ type: 'select', dataIndex: pointInGrid[0] });
          this.onBarSelect.emit(pointInGrid[0]);
          this.onUserBarSelect.emit(this.graph.data[pointInGrid[0]]);
        }

    });
  }

  private calculatePxRatio(): number {
    let valuesArray: number[] = [];
    this.graph.data.forEach((item => {
      if(item.current) {
        valuesArray.push(Math.abs(item.current.value));
      }
      if(item.compare) {
        valuesArray.push(Math.abs(item.compare.value));
      }
      if(item.partial) {
        valuesArray.push(Math.abs(item.partial.value));
      }
    }));
    const maxValue = Math.max(...valuesArray);
    const pxRatio = COMPUTED_CANVAS_HEIGHT_PX / maxValue;
    return pxRatio;
  }

  private convertValueToPx(pxRatio: number, value: number): number {
    return value * pxRatio
  }

  private shouldOffset(value1: number, value2: number): boolean {
    if(value1 < 0 && value2 < 0) {
      return true;
    }
    let shouldOffset = false;
    const pxRatio = this.calculatePxRatio();
    const pxValue1 = this.convertValueToPx(pxRatio, value1);
    const pxValue2 = this.convertValueToPx(pxRatio, value2);
    const distance = Math.abs(pxValue1 - pxValue2);
    
    const minChange = MINCHANGE_PX_OFFSET;
    if(distance <= minChange) {
      shouldOffset = true;
    }
    return shouldOffset;
  }

  private selectBar(index: number, emitresult = true) {
    setTimeout(() => {
      this.myChart?.dispatchAction({ type: 'select', dataIndex: index });
    })
  }

  private selectDefaultBar(): void {
    if (this.myChart) {
      let defaultIndex = this.graph.data.findIndex(item => item.isDefault);
      this.selectBar(defaultIndex);
    }
  }

  private getOffsetLabel(firstValue: number, secondValue: number, flip: boolean) {
    const [mainValue, secondaryValue] = firstValue >= secondValue ? [firstValue, secondValue] : [secondValue, firstValue];
    let mainColor, secondaryColor;
     if (firstValue <= 0 || secondValue <= 0) {
      [mainColor, secondaryColor] = flip ? ['gray', 'white'] : ['white', 'gray'];
    }else {
      [mainColor, secondaryColor] = flip ? ['white', 'gray'] : ['gray', 'white'];
    }
    return {
      formatter: [
        `{${mainColor}|${mainValue}}`,
        `{${secondaryColor}|${secondaryValue}}`
      ].join('\n'),
    };
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
}
