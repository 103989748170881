<ng-container *ngIf="!isLoading">
    <div class="bar-container" >
        <app-horizontal-bar
        *ngFor="let item of graph.data; index as $index; trackBy: trackByFn"
        [maxValue]="maxValue"
        [item]="item"
        [selected]="selectedIndex === $index"
        [withGestures]="withGestures"
        [selectedItem]="selectedIndex === $index ? selectedItem : null"
        (onSelectTitle)="selectTitle($event)"
        (onUpdateItem)="updateItem($event)"
        [isLoading]="isLoading"
        [navState]="navState"
        [sectionTitle]="sectionTitle"
        >
        </app-horizontal-bar>
    </div>
</ng-container>
<ng-container *ngIf="isLoading">
    <div class="chart-container">
        <app-loader></app-loader>
    </div>
</ng-container>
