import { Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductionFinVizView } from 'src/app/model/production-views';
import { NavigationOptions } from 'src/app/model/queryParams';
import { IntersectionState } from '../section-layout/section-layout.component';
import { DataService } from 'src/app/services/data.service';
import { chartTypeMap, GraphType } from 'src/app/model/generic-graph';
import { Subject, takeUntil } from 'rxjs'
@Component({
  selector: 'app-production-kmt-slide',
  templateUrl: './production-kmt-slide.component.html',
  styleUrls: ['./production-kmt-slide.component.scss']
})
export class ProductionKmtSlideComponent implements OnInit {
  @Input() productionKpiGraph:ProductionFinVizView[] = [];
  @Input() navState: NavigationOptions = {};
  @Input() intersection!: Observable<IntersectionState>;
  shouldAnimate = false;
  private _destroyed$ = new Subject();
  public currentPath: any = {};

  constructor(private router: Router,private dataService: DataService){}

  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
      this.intersection.subscribe((state:IntersectionState) => {
        if(state === IntersectionState.Entering) {
          this.shouldAnimate = true;
        }
      })
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
  public navigateToGraph(cellDetails: any){
    const timeRangeMap = ['M','QTD','YTD']
    const currentSlideMap = ['last year','no comparasion','budget']
    window.dataLayer.push({
      'event': 'fireEvent',
      'event_name': 'element_click',
      'division':this?.currentPath?.div || 'ICL',
      'chart_name':this?.navState?.graph || 'Sales',
      'type': 'navigation',
      'section': 'chart',
      'chart_type': chartTypeMap.get(GraphType.PRODUCTION_KTM),
      'chart_state':this?.navState?.dropdown || undefined,
      'time_range':timeRangeMap[this?.navState?.nav || 0], 
      'comparison_scope': currentSlideMap[this?.navState?.slide !== undefined ? this?.navState?.slide : 1], 
      'sub_business_unit':undefined,
      'purpose':'switching chart',
      'description':'scorecard',
      'target_chart_name': `${cellDetails.title}`
    });

    const navOptions: NavigationOptions = {
      nav: this.navState.nav,
      div: cellDetails.division,
      bu: cellDetails.businessUnit ?? undefined,
      sbu: cellDetails.subBusinessUnit ?? undefined,
      buSubtitle: cellDetails.businessUnitSubtitle ?? undefined,
      slide: this.navState.slide,
      graph: this.navState.graph,
    }
    this.router.navigate([], {
      queryParams: navOptions,
    })
  }
}
