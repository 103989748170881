<app-section-layout
  [dataForChart]="graphData"
  [itemTemplate]="potashAvg"
  (onIntersection)="onIntersectionChange($event)"
  *ngIf="graphData"
></app-section-layout>

<ng-template
  #potashAvg
  let-data="data"
  let-navInfo="navInfo"
  let-sectionNavState="sectionNavState"
  let-componentState="componentState"
  let-setComponentState="setComponentState"
>
  <app-line-bar-graph [graphData]="graphData" [graph]="data.graph" [userData]="componentState" (onUserBarSelect)="setComponentState($event)" [isLoading]="isLoading" ></app-line-bar-graph>
</ng-template>
