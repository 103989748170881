export const environment = {
  production: false,
  useApi: true,
  // baseUri: 'http://localhost:3000/',
  //useApi: false,
  baseUri: 'https://api.vibes2-dev.apps4.icldig.icl-group.com/',
  dataApiEndpoint: 'getPaperChart',
  stocksAPIUrl: 'globes-api/getAllPapers',
  histogramStockAPIUrl: `globes-api/getPaperChart?paperid={{paperId}}`,
  adalConfig: {
    clientId: '4331a39e-68e4-4ab8-b58b-66e0e9ad4168',
    issuer: 'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
    strictDiscoveryDocumentValidation: false,
    responseType: 'id_token token',
    scope: '4331a39e-68e4-4ab8-b58b-66e0e9ad4168/.default',
  },
  version: '2.1.3.4',
};
