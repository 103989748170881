import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Animations } from 'src/app/animations';
import { HorizontalBarGraphUnit, HorizontalGraphView, HorizontalItemRow } from 'src/app/model/horizontal-graph';
import { VerticalGraphItem } from 'src/app/model/vertical-views';

const DEFAULT_ITEM_INDEX = 0;
export interface ISelectedItem {
  title?: String;
  isMoreInfo?: boolean;
  unit?: HorizontalBarGraphUnit;
  column?: VerticalGraphItem;
}
@Component({
  selector: 'app-horizontal-bar-graph',
  templateUrl: './horizontal-bar-graph.component.html',
  styleUrls: ['./horizontal-bar-graph.component.scss'],
  animations: [
    Animations.fadeInOut
  ]
})

export class HorizontalBarGraphComponent {
  @Input() selectedItem: ISelectedItem = {};
  @Input() withGestures: boolean = true;
  @Input() graph: HorizontalGraphView = {} as HorizontalGraphView;
  @Input() isLoading: boolean = true;
  @Output() onSelectItem: EventEmitter<ISelectedItem> = new EventEmitter<ISelectedItem>();
  @Input() navState: any = null;
  @Input() sectionTitle: any = null;

  public maxValue: number = 1;
  public selectedTitle: String = '';
  public selectedIndex: number = DEFAULT_ITEM_INDEX;

  constructor() { }

  ngOnInit(): void {
    this.setMaxValue();
    this.initSelectedItem();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedItem']) {
      this.initSelectedItem();
    }
    if(changes['graph']) {
      this.setMaxValue();
      this.initSelectedItem();
    }
  }

  private initSelectedItem(): void {
    if(this.selectedItem && this.selectedItem.title) {
      if(this.selectedTitle !== this.selectedItem.title){
        const index = this.graph.data.findIndex(item => item.title === this.selectedItem.title);
        if(index > -1) {
          this.selectedTitle = this.graph.data[index].title;
          this.selectedIndex = index;
        } else {
          this.selectedTitle = this.graph.data[DEFAULT_ITEM_INDEX].title;
          this.selectedIndex = DEFAULT_ITEM_INDEX;
        }
      }
    } else {
      this.selectedTitle = this.graph.data[DEFAULT_ITEM_INDEX].title;
      this.selectedIndex = DEFAULT_ITEM_INDEX;
    }
  }

  public selectTitle(title: String): void {
    this.onSelectItem.emit({title});
  }

  public updateItem(updatedObject: ISelectedItem) {
    this.onSelectItem.emit({...this.selectedItem, ...updatedObject})
  }

  private setMaxValue(): void {
    this.maxValue = this.graph.data.reduce((acc: number, item: HorizontalItemRow) => {
      if(item.value.value > acc) {
        return item.value.value;
      }
      else {
        return acc;
      }
    }, 0);
  }

  public trackByFn(index: number, item: any): number {
    return index;
  }
}
