import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subject, Subscription, take, takeUntil } from 'rxjs';
import { IStockChartData, IStocksData } from '../../model/stocks-panel';

import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { StockDataService } from 'src/app/services/stock-data.service';
import { DataService } from 'src/app/services/data.service';
import { chartTypeMap, GraphType } from 'src/app/model/generic-graph';

/**
 * @description
 * This component acts as a panel view of stocks information. It also handles a more detailed view of the selected stock in a line graph (Echarts graph).
 */
@Component({
  selector: 'app-stocks-panel',
  templateUrl: './stocks-panel.component.html',
  styleUrls: ['./stocks-panel.component.scss'],
})
export class StocksPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stocksPanelRef') stocksPanelRef!: ElementRef;

  public stocksData$: Observable<IStocksData[] | null> = new Observable<null>();
  private debounceTimer: any;
  public choosenStock: number = 0;
  public options: EChartsOption = {};
  public mergeOptions: EChartsOption = {};
  public myChart: any;
  public stockDataChart$: Observable<IStockChartData | null> = new Observable<null>();
  public stockDataChartSub: Subscription = new Subscription();
  private choosenStockDayChange: number = 0;
  private _destroyed$ = new Subject();
  private firstStock: IStocksData = {} as IStocksData;
  private intervalId: any; // Store the interval ID to clear it if needed
  private intersectionObserver!: IntersectionObserver;
  public currentPath: any = {};
  private tooltipChangeSubject: Subject<any> = new Subject();

  constructor(private stockDataService: StockDataService, private dataService: DataService) {}

  private getWidth(chart: any) {
    try {
      const width = chart.getWidth();
      return width;
    } catch (err) {
      return 0;
    }
  }

  ngOnInit(): void {
    this.stocksData$ = this.stockDataService.normalizedStocksData$;
    this.stockDataChart$ = this.stockDataService.stockChartData$;

    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });

    const fetchDataAndUpdateChart = () => {
      this.stockDataService.getStocks();
    };
    fetchDataAndUpdateChart(); // Initial data fetch
    this.intervalId = setInterval(fetchDataAndUpdateChart, 300000); // Pull every 5 minutes

    this.stocksData$.pipe(take(2)).subscribe((data) => {
      if (data) {
        this.showChartByStock(data[0]);
      }
    });

    this.options = {
      tooltip: {
        backgroundColor: '#002949',
        borderColor: '#1A4F69',
        borderWidth: 2,
        borderRadius: 6,
        padding: 0,
        extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0.0); z-index: 0;',
        trigger: 'axis',
        position: function (pos) {
          return [pos[0] - 35, pos[1] - 46];
        },
        formatter:
          '<div style="padding: 5px;" class="tooltip-stock-container"><div class="triangle"></div><span>{c}</span></div>',
        textStyle: {
          fontSize: 14,
          color: '#CFDADB',
          fontFamily: 'DemoLight',
        },
        axisPointer: {
          lineStyle: {
            opacity: 0,
          },
        },
      },
      xAxis: [
        {
          show: false,
          data: [],
        },
      ],
      yAxis: [
        {
          scale: true,
          type: 'value',
          splitNumber: 3,
          axisLabel: {
            showMinLabel: false,
          },
          axisLine: {
            lineStyle: {
              color: '#40697f',
            },
          },
          splitLine: {
            lineStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: '#002949',
                },
                {
                  offset: 0.5,
                  color: '#025579',
                },
                {
                  offset: 1,
                  color: '#002949',
                },
              ]), 
            },
          },
          position: 'right',
        },
      ],
      grid: {
        left: 10,
        top: 10,
        right: 50,
        bottom: 0,
      },
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: [],
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#FF6F7450',
              },
              {
                offset: 1,
                color: '#002949',
              },
            ]),
          },
          lineStyle: {
            color: '#FF6F74',
            width: 2,
          },
        },
      ],
    };
  }

  ngAfterViewInit(): void {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.dataService.setGraph('StocksPanel');
      }
    }, { threshold: 1 });

    if (this.stocksPanelRef) {
      this.intersectionObserver.observe(this.stocksPanelRef.nativeElement);
    }

    // On chart initialization, hook into the tooltip events
    setTimeout(() => {
      this.stockDataChart$.pipe(takeUntil(this._destroyed$)).subscribe((data: IStockChartData | null) => {
        if (!data) return;

        const upColor = '#00b8d8';
        const downColor = '#ff6f74';
        let xAxisData: number[] = [];

        for (let i = 0; i < data.values.length; i++) {
          xAxisData.push(i);
        }

        this.mergeOptions = {
          xAxis: {
            inverse: true,
            data: xAxisData,
          },
          yAxis: {
            type: 'value',
            min: +data.lowRate * 0.95,
            max: +data.maxRate * 1.05,
          },
          series: [
            {
              data: data.values,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:
                      this.choosenStockDayChange >= 0
                        ? upColor + '50'
                        : downColor + '50',
                  },
                  {
                    offset: 1,
                    color: '#002949',
                  },
                ]),
              },
              lineStyle: {
                color: this.choosenStockDayChange >= 0 ? upColor : downColor,
                width: 2,
              },
            },
          ],
        };

        if (data.values.length > 0) {
          const findHeightOfLastRate =
            ((290 * ((+data.maxRate - data.values[1]) / +data.maxRate)) / 100) * (data.maxRate - data.lowRate);

          this.myChart?.dispatchAction({
            type: 'showTip',
            y: findHeightOfLastRate,
            x: this.getWidth(this.myChart) - 54,
            dontRunAnalytics:true
          });
        } else {
          this.myChart?.dispatchAction({ type: 'hideTip' });
        }
      });
    });

    this.onChartInit = (ec: any) => {
      this.myChart = ec;
      this.myChart.on('showTip', (params: any) => {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }
        if(!params?.dontRunAnalytics && params?.dataIndex !== 0){
          this.debounceTimer = setTimeout(() => {
            this.tooltipChangeSubject.next(params);
          }, 300); 
        }
      });
    };
    this.tooltipChangeSubject.pipe(takeUntil(this._destroyed$)).subscribe((params) => {
      this.handleTooltipChange(params);
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Clean up the IntersectionObserver when the component is destroyed
    if (this.intersectionObserver && this.stocksPanelRef) {
      this.intersectionObserver.unobserve(this.stocksPanelRef.nativeElement);
    }
    // Clean up debouncing
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
  }

  public showChartByStock(stock: IStocksData, index?: number): void {
    if(stock && stock.stockId) {
      this.choosenStock = stock.stockId;
      this.choosenStockDayChange = stock.dayChange;
      this.createStockChartByStockId(stock.stockId);
      if(index !== undefined){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'fireEvent',
          'event_name': 'element_click',
          'division':this?.currentPath?.div || 'ICL',
          'chart_name': 'StocksPanel',
          'type': 'engagement',
          'section': 'chart',
          'chart_type': chartTypeMap.get(GraphType.STOCKS),
          'chart_state':undefined,
          'time_range':undefined,
          'comparison_scope':undefined,
          'sub_business_unit':undefined,
          'purpose':'clicking on a market measure tab',
          'description':stock?.exchange ? `${stock?.exchange}:${stock?.stock}` : stock?.stock
        });
      }
    }
  }

  public createStockChartByStockId(stockId: number): void {
    this.stockDataService.setStockOnChart(stockId);
  }

  public onChartInit(ec: any): void {
    this.myChart = ec;
  }

  private handleTooltipChange(params: any): void {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'fireEvent',
        'event_name': 'element_click',
        'division':this?.currentPath?.div || 'ICL',
        'chart_name': 'StocksPanel',
        'type': 'engagement',
        'section': 'chart',
        'chart_type': chartTypeMap.get(GraphType.STOCKS),
        'chart_state':undefined,
        'time_range':undefined,
        'comparison_scope':undefined,
        'sub_business_unit':undefined,
        'purpose':'displaying number',
        'description':'scorecard'
      });
  }


  public onScroll($event: any): void {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'fireEvent',
        'event_name': 'element_slide',
        'division':this?.currentPath?.div || 'ICL',
        'chart_name': 'StocksPanel',
        'type': 'engagement',
        'section': 'chart',
        'chart_type': chartTypeMap.get(GraphType.STOCKS),
        'chart_state':undefined,
        'time_range':undefined,
        'comparison_scope':undefined,
        'sub_business_unit':undefined,
        'purpose':'sliding between stocks'
      });
    }, 200);
  }
}
