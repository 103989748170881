import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TerritoryRegion } from 'src/app/model/territory-graph';
import { DataService } from 'src/app/services/data.service';
import { Subject, takeUntil } from 'rxjs';
import { GraphType, chartTypeMap} from 'src/app/model/generic-graph';


/**
 * @description
 * This component acts as a container for a world map graph and displays the data about each continent.
 * On clicking some continent her data displays on the screen
 */

@Component({
  selector: 'app-territory-slide',
  templateUrl: './territory-slide.component.html',
  styleUrls: ['./territory-slide.component.scss'],
})
export class TerritorySlideComponent implements OnInit {
  public currentPath: any = {};
  public countryColorAndFocus: string = '#024266';
  public countryDataIndex: number = 0;
  @Input() public selectedContinental: any = 0;
  @Input() navState: any = null;
  public dataIndex: number = this.selectedContinental ? this.selectedContinental.continentIndex : 0;
  public continents: TerritoryRegion[] = [];
  constructor(private  dataService: DataService) { }
  private _destroyed$ = new Subject();

  @ViewChild('mapContainer') mapContainer: any;



  @Input() data: any;


  @Output() public selectedContinentalChange: EventEmitter<any> = new EventEmitter<any>();


  ngOnChanges(changes: any): void { 
    if(changes['data'] ) {
      this.continents = [this.data.graph.northAmerica, this.data.graph.southAmerica, this.data.graph.europe, this.data.graph.asia, this.data.graph.restOfTheWorld, this.data.graph.all];
      setTimeout(() =>{
        if(this.selectedContinental) {
          this.setSelectedContinental(null, this.selectedContinental.continentIndex, false);
          this.showPresentageChages(this.selectedContinental.countryDataIndex);
        } else {
          this.setSelectedContinental(null, this.dataIndex, false)
        }
      })
    }
  }

  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
    this.continents = [this.data.graph.northAmerica, this.data.graph.southAmerica, this.data.graph.europe, this.data.graph.asia, this.data.graph.restOfTheWorld, this.data.graph.all];
    setTimeout(() =>{
      if(this.selectedContinental) {
        this.setSelectedContinental(null, this.selectedContinental.continentIndex, false);
        this.showPresentageChages(this.selectedContinental.countryDataIndex);
      } else {
        this.setSelectedContinental(null, this.dataIndex, false)
      }
    })
   }

  public setSelectedContinental(element: any, index: number, shouldEmit = true): void {
    this.dataIndex = index;
    const container = this.mapContainer.nativeElement;
    const restOfTheWorldIndex = 4;
    const allIndex = 5;
    if(element){
      const timeRangeMap = ['M','QTD','YTD']
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'fireEvent',
        'event_name': 'element_click',
        'division': this?.currentPath?.div || 'ICL',
        'chart_name': 'Sales by Territory',
        'type': 'engagement',
        'section': 'chart',
        'chart_type': chartTypeMap.get(GraphType.TERRITORY),
        'chart_state': undefined,
        'time_range':timeRangeMap[this?.navState?.nav || 0],
        'comparison_scope': undefined,
        'sub_business_unit': undefined,
        'purpose':index === allIndex ? 'reseting chart' : 'displaying region data',
        'description': `${this?.continents?.[index]?.title} via map`,
      });
    }
    container
    .querySelectorAll('.country-area')
    .forEach((countryElement: any) => {
      //HTMLElement
      countryElement.classList.remove('focused');
    });
    const elements = container.querySelectorAll('.map-container > div');

    switch (index) {
      case allIndex:
        break;
      case restOfTheWorldIndex:
        elements[4].querySelectorAll('.country-area').forEach((el: any) => el.classList.add('focused'));
        elements[5].querySelectorAll('.country-area').forEach((el: any) => el.classList.add('focused'));
        break;
      default:
        elements[index].querySelectorAll('.country-area').forEach((el: any) => el.classList.add('focused'));
        break;
    }

    this.countryDataIndex = 0;
    if(shouldEmit) {
      this.selectedContinentalChange.emit({continentIndex: index, countryDataIndex: this.countryDataIndex});
    }
  }

  public showPresentageChages(index: number, countryFromUi:any = undefined): void {
    if(countryFromUi){
      const timeRangeMap = ['M','QTD','YTD']
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'fireEvent',
        'event_name': 'element_click',
        'division': this?.currentPath?.div || 'ICL',
        'chart_name': 'Sales by Territory',
        'type': 'engagement',
        'section': 'chart',
        'chart_type': chartTypeMap.get(GraphType.TERRITORY),
        'chart_state': undefined,
        'time_range':timeRangeMap[this?.navState?.nav || 0],
        'comparison_scope': undefined,
        'sub_business_unit': undefined,
        'purpose':'displaying region data',
        'description': `${countryFromUi.title} via scorecard`,
      });
    }
    this.countryDataIndex = index;
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
}
